import React from 'react'
import styled from '@emotion/styled'
import peg from 'src/pegTheme'

import linkedin from 'src/images/icons/linkedin.svg'
import twitter from 'src/images/icons/twitter.svg'
import email from 'src/images/icons/email.svg'

export default ({ network, handle }) => {
  switch(network) {
    case 'linkedin':
      return (
        <SocialMediaLinkInner target='_blank' rel='noopener noreferrer' href={ `https://www.linkedin.com/in/${ handle }/` }>
          <SocialIcon src={ linkedin } />
        </SocialMediaLinkInner>
      )
    case 'twitter':
      return (
        <SocialMediaLinkInner target='_blank' rel='noopener noreferrer' href={ `https://twitter.com/${ handle }` }>
          <SocialIcon src={ twitter } />
        </SocialMediaLinkInner>
      )
    case 'email':
      return (
        <SocialMediaLinkInner href={ `mailto:${ handle }` }>
          <SocialIcon src={ email } />
        </SocialMediaLinkInner>
      )
    default:
      return null
  }
}

const SocialIcon = styled.img`
  height: 20px;
  vertical-align: -2px;
  transition: opacity 0.3s ease;
`

const SocialMediaLinkInner = styled.a`
  margin: 0 ${ peg.spacing('xs') };

  &:hover ${ SocialIcon } {
    opacity: 0.7;
  }
`
