import React from 'react'
import Layout, { Container } from 'src/components/Layout'
import { Helmet } from 'react-helmet'
import { Title } from 'src/components/typography'
import styled from '@emotion/styled'
import peg, { responsive } from 'src/pegTheme'
import founders from 'src/images/founders.png'
import SocialMediaLink from 'src/components/team/SocialMediaLink'

export default () => (
  <Layout>
    <Helmet>
      <title>About | YouTube & Instagram Influencer Marketing Tool & Platform | Peg</title>
      <meta name='description' content='Learn more about our 3 founders and what inspired them to create the most complete YouTube & Instagram influencer marketing and analytics tool' />
    </Helmet>

    <AboutContainer>
      <Title>About Us</Title>

      <AboutText>We believe that independent video creators are the future of content.</AboutText>

      <AboutText>By building tools that help creators across the globe we hope to improve the world's video content. Boom.</AboutText>

      <AboutText>Peg was founded in 2014 by 3 nerds in London, UK:</AboutText>

      <FoundersSection>
        <FoundersContacts>
          <FounderDetails>
            <Name>Julia Tan</Name>
            <SocialMediaLink network='linkedin' handle='juliatan' />
            <SocialMediaLink network='twitter' handle='bionicjulia' />
            <SocialMediaLink network='email' handle='julia@peg.co' />
          </FounderDetails>

          <FounderDetails>
            <Name>Nic Yeeles</Name>
            <SocialMediaLink network='linkedin' handle='NicYeeles' />
            <SocialMediaLink network='twitter' handle='nicyeeles' />
            <SocialMediaLink network='email' handle='nic@peg.co' />
          </FounderDetails>

          <FounderDetails>
            <Name>Alex Peattie</Name>
            <SocialMediaLink network='linkedin' handle='apeattie' />
            <SocialMediaLink network='twitter' handle='alexpeattie' />
            <SocialMediaLink network='email' handle='alex@peg.co' />
          </FounderDetails>
        </FoundersContacts>

        <FoundersPic src={ founders } alt='Julia, Nic and Alex' />
      </FoundersSection>
    </AboutContainer>
  </Layout>
)

const AboutContainer = styled(Container)`
  align-items: center;
`

const AboutText = styled.p`
  margin-top: 0;
  max-width: 440px;
  text-align: center;
  padding: 0 ${ peg.spacing('m') };
`

const FoundersSection = styled.section`
  max-width: 900px;
  display: flex;
  flex-direction: column;
`

const FoundersContacts = styled.div`
  display: flex;
  margin: ${ peg.spacing('xl') } 0;
  ${ responsive('flex-direction', { d: 'row', m: 'column' }) }
`

const FounderDetails = styled.div`
  ${ responsive('width', { d: '33%', m: '100%' }) }
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${ peg.spacing('m') };
`

const FoundersPic = styled.img`
  width: 100%;
`

const Name = styled.span`
  margin-right: ${ peg.spacing('s') };
`
